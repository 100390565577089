/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { MeshReflectorMaterial } from "@react-three/drei";
import * as THREE from "three";
import Model from "./MuseumModel.jsx";
import { Vignette, Noise, EffectComposer } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import MuseumText from "./MuseumText.jsx";
import MuseumLights from "./MuseumLights.jsx";
import MuseumSnow from "./MuseumSnow.jsx";

export default function Museum(props) {
  const modelRef = useRef();

  // const { blendMode, premultily, opacity } = useControls({
  //   blendMode: {
  //     options: {
  //       add: BlendFunction.ADD,
  //       alpha: BlendFunction.ALPHA,
  //       average: BlendFunction.AVERAGE,
  //       colorBurn: BlendFunction.COLOR_BURN,
  //       color_dodge: BlendFunction.COLOR_DODGE,
  //       darken: BlendFunction.DARKEN,
  //       difference: BlendFunction.DIFFERENCE,
  //       divide: BlendFunction.DIVIDE,
  //       exclusion: BlendFunction.EXCLUSION,
  //       hardLight: BlendFunction.HARD_LIGHT,
  //       hardMix: BlendFunction.HARD_MIX,
  //       hue: BlendFunction.HUE,
  //       invert: BlendFunction.INVERT,
  //       invertRGB: BlendFunction.INVERT_RGB,
  //       lighten: BlendFunction.LIGHTEN,
  //       linear_burn: BlendFunction.LINEAR_BURN,
  //       linearDodge: BlendFunction.LINEAR_DODGE,
  //       linearLight: BlendFunction.LINEAR_LIGHT,
  //       luminosity: BlendFunction.LUMINOSITY,
  //       mutiply: BlendFunction.MULTIPLY,
  //       normal: BlendFunction.NORMAL,
  //       overlay: BlendFunction.OVERLAY,
  //       pinLight: BlendFunction.PIN_LIGHT,
  //       saturation: BlendFunction.SATURATION,
  //       screen: BlendFunction.SCREEN,
  //       viviLight: BlendFunction.VIVID_LIGHT,
  //     },
  //   },
  //   opacity: {
  //     value: 0.5,
  //     min: 0,
  //     max: 1,
  //     step: 0.01,
  //   },
  //   premultily: { value: true },
  // });

  useFrame(({ mouse }, delta) => {
    modelRef.current.position.x = THREE.MathUtils.lerp(
      modelRef.current.position.x,
      mouse.x * -0.1,
      0.03
    );
    modelRef.current.position.z = THREE.MathUtils.lerp(
      modelRef.current.position.x,
      mouse.x * 0.1,
      0.03
    );
    modelRef.current.position.y = THREE.MathUtils.lerp(
      modelRef.current.position.y,
      mouse.y * -0.1,
      0.03
    );
  });
  return (
    <group ref={modelRef}>
      {/* EFFECTS */}
      <EffectComposer disableNormalPass>
        <Vignette offset={0.4} darkness={0.5} />
        <Noise opacity={0.03} blendFunction={BlendFunction.AVERAGE} />
      </EffectComposer>

      <Model {...props} />
      <group position={[-0.387, 0.761, -4.902]} rotation={[0, -0.922, 0]}>
        <mesh rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[0.45, 0.65]} />
          <MeshReflectorMaterial resolution={512} mirror={0.9} color="white" />
        </mesh>
      </group>
      <MuseumLights />
      <MuseumText />
      <MuseumSnow position={[0, 0, -16]} range={[26, 15]} count={1000} />
      <MuseumSnow position={[-19.5, 0, -4.5]} range={[16, 25]} count={1500} />
    </group>
  );
}
