import React, { useState, useEffect } from "react";
import { useTexture, useEnvironment } from "@react-three/drei";
import * as THREE from "three";

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

export default function MuseumModel({ nodes, materials, start }) {
  useEffect(() => {
    materials.concrete_front_unlit.dispose();
    materials.concrete_back_unlit.dispose();
    materials.concrete_floor_unlit.dispose();
    materials.wood_floor_unlit.dispose();
    materials.ceiling_unlit.dispose();
    materials.pavement_unlit.dispose();
    materials.rock_unlit.dispose();
    materials.snow_unlit.dispose();
    materials.pine_tree_unlit.dispose();
  }, [start]);

  const bun_envMap = useEnvironment({ files: "./textures/bun_envMap.hdr" });
  const voice_mic_envMap = useEnvironment({
    files: "./textures/voice_mic_envMap.hdr",
  });
  const famwood_envMap = useEnvironment({
    files: "./textures/famwood_envMap.hdr",
  });
  const salt_envMap = useEnvironment({
    files: "./textures/salt_envMap.hdr",
  });

  return (
    <>
      <mesh
        name="Treelamp_trans"
        geometry={nodes.Treelamp_trans.geometry}
        material={materials.treelamp_trans}
        position={[10.074, 1.289, 4.34]}
      />
      <mesh
        name="Vioce_trans"
        geometry={nodes.Vioce_trans.geometry}
        material={materials.voice_trans}
        position={[-4.696, 1.606, -2.396]}
        rotation={[0, 1.315, 0]}
      >
        <meshBasicMaterial
          color={"#cccccc"}
          transparent
          alphaMap={materials.lamps_trans.roughnessMap}
        />
      </mesh>
      <mesh
        name="Candle_trans"
        geometry={nodes.Candle_trans.geometry}
        material={materials.candle_trans}
        position={[2.794, 3.944, -4.849]}
        rotation={[Math.PI, -0.398, Math.PI]}
      />
      <mesh
        name="Lamps_trans"
        geometry={nodes.Lamps_trans.geometry}
        material={materials.lamps_trans}
        position={[2.794, 3.944, -4.849]}
        rotation={[Math.PI, -0.398, Math.PI]}
      />
      <mesh
        name="Famwood_metal"
        geometry={nodes.Famwood_metal.geometry}
        material={materials.famwood_metal}
        position={[-9.235, 1.014, 0.15]}
        rotation={[0, Math.PI / 2, 0]}
      >
        <meshStandardMaterial
          {...materials.famwood_metal}
          envMap={famwood_envMap}
          // envMapIntensity={2}
        />
      </mesh>
      <mesh
        name="Lamps_metal"
        geometry={nodes.Lamps_metal.geometry}
        material={materials.lamps_metal}
        position={[4.256, 3.268, -4.521]}
        rotation={[-Math.PI, 0.476, -Math.PI]}
      >
        <meshStandardMaterial
          {...materials.lamps_metal}
          envMap={salt_envMap}
          // envMapIntensity={2}
        />
      </mesh>
      <mesh
        name="Voice_metal"
        geometry={nodes.Voice_metal.geometry}
        material={materials.voice_metal}
        position={[-4.426, 1.426, -2.761]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.028}
      >
        <meshStandardMaterial
          {...materials.voice_metal}
          roughness={1.5}
          envMap={voice_mic_envMap}
        />
      </mesh>
      <mesh
        name="Bun_metal"
        geometry={nodes.Bun_metal.geometry}
        // material={materials.bun_metal}
        position={[-0.387, 0.903, -4.902]}
        rotation={[0, -0.922, 0]}
        // rotation={[0, Math.PI, 0]}
      >
        <meshStandardMaterial {...materials.bun_metal} envMap={bun_envMap} />
      </mesh>
      <mesh
        name="Salon_unlit"
        geometry={nodes.Salon_unlit.geometry}
        material={materials.salon_unlit}
        position={[-10.983, 4.452, 1.823]}
      />
      <mesh
        name="Easel_unlit"
        geometry={nodes.Easel_unlit.geometry}
        material={materials.easel_unlit}
        position={[-10.387, 1.175, -5.172]}
        rotation={[1.354, 0.205, -0.747]}
      />
      <mesh
        name="Vase_unlit"
        geometry={nodes.Vase_unlit.geometry}
        material={materials.vase_unlit}
        position={[6.191, 0.542, -7.526]}
      />
      <mesh
        name="Ripple_unlit"
        geometry={nodes.Ripple_unlit.geometry}
        material={materials.ripple_unlit}
        position={[0.595, 3.344, 3.568]}
        scale={[1, 0.45, 1]}
      />
      <mesh
        name="P5_unlit"
        geometry={nodes.P5_unlit.geometry}
        material={materials.p5_unlit}
        position={[-2.295, 4.38, 7.719]}
        rotation={[Math.PI, 0, Math.PI]}
      />
      <mesh
        name="Famwood_stairs_unlit"
        geometry={nodes.Famwood_stairs_unlit.geometry}
        material={materials.famwood_handrail_unlit}
        position={[-8.549, 0.498, 0.332]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        name="Vr_headset_unlit"
        geometry={nodes.Vr_headset_unlit.geometry}
        position={[-3.397, 1.486, 4.743]}
        rotation={[0, 0.082, 0]}
      >
        <meshBasicMaterial
          map={materials.vr_unlit.map}
          alphaMap={materials.vr_unlit.aoMap}
          transparent
          side={THREE.DoubleSide}
        />
      </mesh>
      <mesh
        name="Bun_unlit"
        geometry={nodes.Bun_unlit.geometry}
        material={materials.bun_unlit}
        position={[-0.387, 0.903, -4.902]}
        rotation={[0, -0.922, 0]}
      />
      <mesh
        name="0_handrail_wall"
        geometry={nodes["0_handrail_wall"].geometry}
        material={materials.pendant_window_unlit}
      />
      <mesh
        name="0_pendant"
        geometry={nodes["0_pendant"].geometry}
        material={materials.pendant_window_unlit}
        position={[-8.432, 5.869, 2.768]}
      />
      <mesh
        name="0_truss_side"
        geometry={nodes["0_truss_side"].geometry}
        material={materials.pendant_window_unlit}
        position={[-8.432, 5.869, 2.768]}
      />
      <mesh
        name="0_window"
        geometry={nodes["0_window"].geometry}
        material={materials.pendant_window_unlit}
        position={[-8.432, 5.869, 2.768]}
      />
      <mesh
        name="Iwt_unlit"
        geometry={nodes.Iwt_unlit.geometry}
        material={materials.iwt_allcolor_unlit}
        position={[2.761, -0.241, 8.038]}
      />
      <mesh
        name="Allcolor_unlit"
        geometry={nodes.Allcolor_unlit.geometry}
        material={materials.iwt_allcolor_unlit}
      />
      <mesh
        name="Papers"
        geometry={nodes.Papers.geometry}
        material={materials.papers_unlit}
        position={[-9.275, -1.65, 8.361]}
        rotation={[-Math.PI, 0.173, -Math.PI]}
        scale={[1, 1.308, 1]}
      />
      <mesh
        name="0_grey_interior"
        geometry={nodes["0_grey_interior"].geometry}
        material={materials.greywall_unlit}
      />
      <mesh
        name="Treelamp_unlit"
        geometry={nodes.Treelamp_unlit.geometry}
        material={materials.treelamp_unlit}
        position={[10.074, 1.289, 4.34]}
      />
      <mesh
        name="Lamps_unlit"
        geometry={nodes.Lamps_unlit.geometry}
        material={materials.lamps_unlit}
        position={[4.256, 3.268, -4.521]}
        rotation={[-Math.PI, 0.476, -Math.PI]}
        scale={1.001}
      />
      <mesh
        name="Voice_unlit"
        geometry={nodes.Voice_unlit.geometry}
        material={materials.voice_logo_hbeam_unlit}
        position={[-3.95, 1.783, -2.188]}
        rotation={[0, 1.3, 0]}
      />
      <mesh
        name="0_truss"
        geometry={nodes["0_truss"].geometry}
        material={materials.truss_unlit}
        position={[2.438, 5.866, -1.783]}
      />
      <mesh
        name="0_h_beam"
        geometry={nodes["0_h_beam"].geometry}
        material={materials.voice_logo_hbeam_unlit}
        position={[13.053, 3.452, 4.982]}
      />
      <mesh
        name="0_logo"
        geometry={nodes["0_logo"].geometry}
        material={materials.voice_logo_hbeam_unlit}
        position={[-6.201, 1.308, -6.076]}
        rotation={[Math.PI / 2, 0, Math.PI]}
      />
      <mesh
        name="Zooba_unlit"
        geometry={nodes.Zooba_unlit.geometry}
        material={materials.iwt_allcolor_unlit}
        position={[5.43, 1.752, 14.188]}
        rotation={[Math.PI / 2, 0, Math.PI]}
      />
      <mesh
        name="Vr_panel_unlit"
        geometry={nodes.Vr_panel_unlit.geometry}
        position={[-3.397, 1.486, 4.743]}
        rotation={[0, 0.082, 0]}
      >
        <meshBasicMaterial
          map={materials.vr_unlit.map}
          alphaMap={materials.vr_unlit.aoMap}
          transparent
          side={THREE.DoubleSide}
        />
      </mesh>
      <mesh name="Plane019" geometry={nodes["0_concrete_front"].geometry}>
        <meshBasicMaterial
          map={materials.concrete_front_unlit.map}
          aoMap={materials.concrete_front_unlit.aoMap}
        />
      </mesh>
      <mesh name="Plane019_1" geometry={nodes["0_concrete_back"].geometry}>
        <meshBasicMaterial
          map={materials.concrete_back_unlit.map}
          aoMap={materials.concrete_back_unlit.aoMap}
        />
      </mesh>
      <mesh
        name="0_trees"
        geometry={nodes["0_trees"].geometry}
        position={[-9.843, -1.703, -9.999]}
        rotation={[Math.PI, -1.561, Math.PI]}
      >
        <meshBasicMaterial
          map={materials.pine_tree_unlit.map}
          aoMap={materials.pine_tree_unlit.aoMap}
        />
      </mesh>
      <group
        name="0_landscape"
        position={[-8.101, -0.265, -6.984]}
        rotation={[-0.033, -0.012, -0.108]}
        scale={[1.007, 1.496, 1.001]}
      >
        <mesh name="Icosphere007" geometry={nodes.Icosphere007.geometry}>
          <meshBasicMaterial
            map={materials.snow_unlit.map}
            aoMap={materials.snow_unlit.aoMap}
          />
        </mesh>
        <mesh name="Icosphere007_1" geometry={nodes.Icosphere007_1.geometry}>
          <meshBasicMaterial
            map={materials.rock_unlit.map}
            aoMap={materials.rock_unlit.aoMap}
          />
        </mesh>
        <mesh name="Icosphere007_2" geometry={nodes.Icosphere007_2.geometry}>
          <meshBasicMaterial
            map={materials.pavement_unlit.map}
            aoMap={materials.pavement_unlit.aoMap}
          />
        </mesh>
      </group>
      <group name="0_floor_ceiling">
        <mesh name="Plane024" geometry={nodes.Plane024.geometry}>
          <meshBasicMaterial
            map={materials.wood_floor_unlit.map}
            aoMap={materials.wood_floor_unlit.aoMap}
          />
        </mesh>
        <mesh name="Plane024_1" geometry={nodes.Plane024_1.geometry}>
          <meshBasicMaterial
            map={materials.ceiling_unlit.map}
            aoMap={materials.ceiling_unlit.aoMap}
          />
        </mesh>
        <mesh name="Plane024_2" geometry={nodes.Plane024_2.geometry}>
          <meshBasicMaterial
            map={materials.concrete_floor_unlit.map}
            aoMap={materials.concrete_floor_unlit.aoMap}
          />
        </mesh>
      </group>
      <group name="0_concrete_back_color">
        <mesh name="Plane023" geometry={nodes.Plane023.geometry}>
          <meshBasicMaterial
            map={materials.concrete_back_unlit.map}
            aoMap={materials.concrete_back_unlit.aoMap}
          />
        </mesh>
        <mesh
          name="Plane023_1"
          geometry={nodes.Plane023_1.geometry}
          material={materials.iwt_allcolor_unlit}
        >
          <meshBasicMaterial
            map={materials.iwt_allcolor_unlit.map}
            aoMap={materials.iwt_allcolor_unlit.aoMap}
          />
        </mesh>
      </group>
    </>
  );
}
